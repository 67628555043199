import React, { useRef, useState, useEffect } from 'react';
import './LandingPage.css'; // Use the same CSS file
import { Link } from 'react-router-dom';
import fw2 from './FirewallNew.png';
import Bots from './bot.png';
import DDos from './ddos.png';


function FeaturesPage() {
  const sections = useRef([]); // Ref to hold section references for scrolling
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null); // Ref to the carousel container

  // useEffect(() => {
  //   // Update the sections array after the component renders
  //   sections.current = Array.from(containerRef.current.children);
  // }, []); // Empty dependency array ensures this runs only once after initial render


  // Handle button clicks to update currentIndex and then scroll
  // const handlePrevClick = () => {
  //   setCurrentIndex((prevIndex) => {
  //     const newIndex = Math.max(0, prevIndex - 1);
  //     scrollToSection(newIndex); // Scroll after updating the index
  //     return newIndex;
  //   });
  // };

  // const handleNextClick = () => {
  //   setCurrentIndex((prevIndex) => {
  //     const newIndex = Math.min(sections.current.length - 1, prevIndex + 1);
  //     scrollToSection(newIndex); // Scroll after updating the index
  //     return newIndex;
  //   });
  // };


  // const scrollToSection = (index) => {
  //   const container = containerRef.current;
  //   const section = sections.current[index];
  
  //   // Calculate the target scroll position 
  //   const targetScrollLeft = section.offsetLeft - (container.offsetWidth - section.offsetWidth) / 2; // Center the section
  
  //   container.scrollTo({
  //     left: targetScrollLeft,
  //     behavior: 'smooth' 
  //   });
  // };
  return (
    <div>
       {/* <header>
         <nav>
           <ul>
           <li><Link to="/features">Features</Link></li>
             <li><Link to="/pricing">Pricing</Link></li>
             <li><Link to="/login">Login</Link></li>
             <li><Link to="/account">Account</Link></li>
           </ul>
         </nav>
       </header> */}
      <div>
      {/* <div className="carousel-wrapper">
      <button onClick={handlePrevClick} disabled={currentIndex === 0} className="prev-button">
          </button> */}
      {/* <div className="carousel-container" ref={containerRef}> */}
      <div className="feature-container" style={{marginTop:"-50px"}}>
  <div className="widget">
    {/* Content for the first section */}
    <h1 stlye={{textAlgin: "left"}}>Web Application Firewall (WAF)</h1>
    <div class="text-image-container alternate">
    <div class="image-content">
    <img src={fw2} style={{width: "150px", height: "150px"}}></img>
  </div>
  <div class="text-content">
    <p>Protects your website from common web attacks like SQL injection, cross-site scripting (XSS), and more.<br></br> 
      Our intelligent WAF filters out malicious traffic, keeping your site safe and secure.</p>
  </div>
  </div>
  </div>
  <div className="widget"></div>
  <div className="widget"></div>
  <div className="widget" style={{marginTop: "-100px"}}>
    {/* Content for the second section */}
        <h1>DDoS Mitigation</h1>
        <div class="text-image-container alternate">
        <div class="image-content">
          <img src={DDos} style={{width: "200px", height: "200px", marginRight: "-20px"}}></img>
          </div>
        <div class="text-content">
        <p>Defend your website against Distributed Denial of Service (DDoS) attacks that can overwhelm your server and cause downtime.
          Our DDoS protection automatically detects and mitigates such attacks, ensuring your site remains accessible.</p>
          </div>
          </div>
  </div>
  <div className="widget"></div>
  <div className="widget"></div>
  {/* Add more carousel-section divs as needed */}  
  <div className="widget" style={{marginTop: "-100px"}}>
           <h1>Bot Protection</h1>
           <div class="text-image-container">
           <div class="text-content">
          <p>Identify and block malicious bots that can scrape your content, perform brute-force attacks, or engage in other harmful activities.
            Our bot detection system helps maintain the integrity of your website and protects your valuable data.</p>
</div>
<div class="image-content">
<img src={Bots} style={{width: "180px", height: "180px", marginLeft: "-20px"}}></img>
</div>
</div>
</div>
</div>
          {/* <button onClick={handleNextClick} disabled={currentIndex   
    === sections.current.length - 1} className="next-button">
          </button>
      </div> */}
      </div>
      </div>
      

  );
}

export default FeaturesPage;
