import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Import your blog-specific CSS

function PrivacyPolicy() {  // Pass in your blog post data

  return (
    <div>
      <header>
        <div class="nav-container">
        <nav >
          <ul>
          <li><Link to="/">Home</Link></li>
            </ul>
            <ul class="right-links">
            <li><Link to="/login">Login</Link></li> {/* Use Link for navigation */}
            <li><Link to="/account">Account</Link></li>
          </ul>
        </nav>
        </div>
      </header>
    <section className="tos"  style={{ textAlign: 'center', marginLeft: '50px', marginRight: '50px'}}>
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

      <p className="mb-2"  style={{ textAlign: 'center'}}>
        At Parapluie Technologies Limited, we are committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and share your personal information when you use our product.
      </p>

      <h2 className="text-2xl font-semibold mt-4 mb-2">Information We Collect</h2>

        <p>Personal Information: We store the personal information you input when you sign up.</p>
        <p>Usage Data: We record the number of requests made to your site so that we can deduct credits appropriately.</p>
        <p>Cookies and Similar Technologies: We store a cookie when you login to keep you logged in for a session duration.</p> 

      <h2 className="text-2xl font-semibold mt-4 mb-2" >How We Use Your Information</h2>

        <p>To provide and maintain our Service.</p>
        <p>To communicate with you, including sending you updates, notifications, and marketing materials.</p>

      <p className="mt-4" style={{ textAlign: 'center'}}>
        By using our Service, you consent to the collection and use of your information as described in this Privacy Policy.
      </p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
    </section>
    </div>
  );
}

export default PrivacyPolicy;