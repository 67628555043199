import React, { useEffect, useRef, useState } from "react";

window.onerror = function (message, source, lineno, colno, error) {
  if (message.includes("PayPal SDK detected element removed from DOM")) {
    // Silently ignore this specific error
    return true; // Prevent default error reporting
  } else {
    // Log or report other errors as needed
    console.log(error)
    console.error(error); // Or send error to your logging service
    return false;  // Allow default error reporting
  }
};

// Example function to show a result to the user. Your site's UI library can be used instead.
function resultMessage(message) {
  const container = document.querySelector("#result-message");
  container.innerHTML = message;
}

const PayPalButton = ({ amount, currency = "USD", onPaymentSuccess, onPaymentError, userId }) => {
  const paypalRef = useRef();

  // new state variable
  const [rendered, setRendered] = useState(false);


  useEffect(() => {
    // only run this effect once.

    try {
      // The code that's likely causing the error
      // ...
    if (!rendered && window.paypal) { 

      window.paypal.ErrorHandler = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        call: function (err) {
          // Do nothing (or add custom logging)
          console.log("PayPal SDK Error (silenced):", err); // Optional logging
        },
      };

      window.paypal.Buttons({
        async createOrder() {
          console.log('Creating order.')
          try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/api/orders", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              // use the "body" param to optionally pass additional order information
              // like product ids and quantities
              body: JSON.stringify({
                    currency: currency,
                    amount: amount,
                  }),
                  credentials: 'include'
            });
    
            const orderData = await response.json();

            console.log('Order created: ', orderData)
    
            if (orderData.id) {
              return orderData.id;
            } else {
              console.log('Error creating order')
              const errorDetail = orderData?.details?.[0];
              const errorMessage = errorDetail
                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                : JSON.stringify(orderData);
    
              throw new Error(errorMessage);
            }
          } catch (error) {
            console.error(error);
            resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
          }
        },
        async onApprove(data, actions) {
          // We'll have to check this of course.
          console.log('Capturing order: ', data)
          try {
            // Send the capture request to the backend.
            // Could this just be sent directly?  
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/orders/capture`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                orderId: data.orderID,
                amount: amount
              }),
              credentials: 'include'
            });
    
            const orderData = await response.json();

            console.log('Got orderData as: ', orderData)
            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you message
    
            const errorDetail = orderData?.details?.[0];
            console.log(errorDetail)
    
            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
              console.log(1)
              // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
              return actions.restart();
            } else if (errorDetail) {
              console.log(2)
              // (2) Other non-recoverable errors -> Show a failure message
              throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
            } else if (!orderData.purchase_units) {
              console.log(3)
              throw new Error(JSON.stringify(orderData));
            } else {
              console.log(4)
              // (3) Successful transaction -> Show confirmation or thank you message
              // Or go to another URL:  actions.redirect('thank_you.html');
              const transaction =
                orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

              console.log(transaction)

              // Its having the issue here then.
              resultMessage(
                `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`,
              );

              console.log(
                "Capture result",
                orderData,
                JSON.stringify(orderData, null, 2),
              );
              await onPaymentSuccess(transaction);
            }
          } catch (error) {
            console.error(error);
            resultMessage(
              `Sorry, your transaction could not be processed...<br><br>${error}`,
            );
          }
        },
      })
      .render(paypalRef.current);

      setRendered(true);
    }
  } catch (err) {
    // Log the error for debugging
    console.error("Error in handleError:", err); 

    // Optionally, return a default value or handle the error silently
    return null; // Or some other default value
}
  }, [amount, currency, onPaymentSuccess, onPaymentError, rendered]); // Added the new state variable to the dependency array to control rendering

//   useEffect(() => {
//     // Only render buttons if the script is loaded
//     try {
//       // The code that's likely causing the error
//       // ...
//     if (window.paypal) {
//       // Remove any existing PayPal buttons (this should only run once)
//       const existingButtons = document.querySelectorAll('.paypal-buttons');
//       if (existingButtons.length > 1) {  // Check if there are duplicates
//         existingButtons[0].remove();      // Remove the first one (or any specific one you want to keep)
//       }
//     }
//   } catch (err) {
//     // Log the error for debugging
//     console.error("Error in handleError:", err); 

//     // Optionally, return a default value or handle the error silently
//     return null; // Or some other default value
// }
// });

  return <div ref={paypalRef} />;
};

export default PayPalButton;