import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage';
import AccountPage from './components/AccountPage';
import PricingPage from './components/PricingPage';
import CheckoutPage from './components/CheckoutPage';
import FeaturesPage from './components/FeaturesPage';
import BlogPage from './components/BlogPage';
import SignupPage from './components/SignupPage';
import Footer from './components/Footer';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import { isMobile } from 'react-device-detect';

function App() {
  return (
    <Router>
      <div>
      {isMobile ? (
          <div>Mobile.</div>
        ) : (
          <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/terms-of-service" element={< TermsOfService/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        </div>
      )}
      </div>
    </Router>
  );
}

export default App;