import React, { useState, useEffect, useRef } from "react";
import Cookies from 'js-cookie';
import PayPalButton from "./PayPalButton"; // Import your PayPalButton component
import { useLocation } from 'react-router-dom';
import "./CheckoutPage.css"
import { Link } from 'react-router-dom';

// Example function to show a result to the user. Your site's UI library can be used instead.
function resultMessage(message) {
  const container = document.querySelector("#result-message");
  container.innerHTML = message;
}

function CheckoutPage() {
  const [selectedOption, setSelectedOption] = useState("credits"); // Default to credits
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isSdkReady, setIsSdkReady] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const preselectedCredits = searchParams.get('credits');
  const [credits, setCredits] = useState(preselectedCredits || ''); // Initialize with the preselected value
  const pricing = {"100": 4.99, "1000": 39.99}

  useEffect(() => {
    if (window.paypal) {
      setIsSdkReady(true);
    }
  }, []);

  useEffect(() => {
    // Load the PayPal script
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=ARmCGK8AVQh8wtQUAKyCMIoutUZ_qqs_23PpDnuVnIRzWJY6M20QFyOXhMzfzg8TJlkb9jEf5q8fXwFq&currency=USD`;
    script.async = true;
    script.onload = () => setScriptLoaded(true);
    document.head.appendChild(script);

    // Clean up when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  // This is now handled by the webhook.
  const handlePaymentSuccess = async (details) => {
    // Handle successful payment
    console.log("Payment Success:", details);
  };

  const handlePaymentError = (error) => {
    // Handle payment errors
    console.error("Payment Error:", error);
    // Display an error message to the user
  };

  return (
    <div>
      <header>
        <div class="nav-container">
        <nav >
          <ul>
          <li><Link to="/">Home</Link></li>
            </ul>
            <ul class="right-links">
            <li><Link to="/login">Login</Link></li> {/* Use Link for navigation */}
            <li><Link to="/account">Account</Link></li>
          </ul>
        </nav>
        </div>
      </header>

    <div className="payment-page">
      {scriptLoaded ? (
        <div>
      <h2>Choose Your Payment Option</h2>
      <div className="checkout-container">
        {/* Payment Options */}
        {/* Payment Section (Conditional Rendering) */}
        <div className="payment-section">
        <div className="dropdown">
          {/* Your UI for selecting credit amounts */}
          <select value={credits} onChange={(e) => setCredits(e.target.value)} style={{width: "100%"}}>
            <option value="100" style={{width: "100%"}}>100 Credits</option>
            <option value="1000" style={{width: "100%"}}>1000 Credits</option>
            {/* Add more options as needed */}
          </select>
          <br></br>
          <br></br>
          <PayPalButton amount={pricing[credits]} onPaymentSuccess={handlePaymentSuccess} onPaymentError={handlePaymentError} />
        </div>
      </div>
      </div>
      <p id="result-message"></p>
      </div>
    ): (
      <p>Loading PayPal...</p>
    )
}
</div>
</div>
)
}

export default CheckoutPage;