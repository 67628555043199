import React, { useState } from 'react';
import './LandingPage.css'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function SignupPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(true); // State to toggle login/signup
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  const [ip, setIP] = useState('');
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false); 
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Clear previous errors

    const url = isSignUp ? process.env.REACT_APP_BACKEND_URL + '/api/signup/' : process.env.REACT_APP_BACKEND_URL + '/api/login/';
    const data = isSignUp ? { username, password, email, domain, ip } : { username, password };
    console.log('included.....');
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data),
        credentials: 'include'
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData); // Assuming your backend returns a success message or token
        // Handle successful login/signup (e.g., redirect, store token)
        setShowSuccess(true); 
        setTimeout(() => {
          setShowSuccess(false);
          navigate("/account", { replace: true }); 
        }, 2000); 
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'An error occurred'); // Show error message from backend
      }
    } catch (err) {
      setError('Network error. Please try again later.');
    }
  };

  const redirectLogin = () => {
    if (isSignUp) {
      navigate('/login'); // Replace '/your-desired-route' with the actual path
    } else {
      navigate('/signup');
    }
  };

  return (
    <div>
      <header>
        <div class="nav-container">
        <nav >
          <ul>
          <li><Link to="/">Home</Link></li>
            </ul>
            <ul class="right-links">
            <li><Link to="/login">Login</Link></li> {/* Use Link for navigation */}
            <li><Link to="/account">Account</Link></li>
          </ul>
        </nav>
        </div>
      </header>
    <form onSubmit={handleSubmit}>
      <h2 style={{"textAlign": "left"}}>{isSignUp ? 'Sign Up' : 'Login'}</h2>
      <div>
        <label htmlFor="username">Username:</label>
        <input className="cta-input" type="text" id="username" value={username} onChange={e => setUsername(e.target.value)} />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input className="cta-input" type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
      </div>
      {isSignUp && ( // Only show email field during signup
        <div>
          <label htmlFor="email">Email:</label>
          <input 
            type="email" 
            id="email" 
            className="cta-input"
            value={email} 
            onChange={e => setEmail(e.target.value)} 
          />
          <label htmlFor="domain">Domain:</label>
          <input 
            id="domain" 
            className="cta-input"
            value={domain} 
            onChange={e => setDomain(e.target.value)} 
          />
          <label htmlFor="ip">Your Origin Server's IP Address:</label>
          <input 
            id="ip" 
            className="cta-input"
            value={ip} 
            onChange={e => setIP(e.target.value)} 
          />
        </div>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
      <br></br>
      <button type="submit" className="cta-button" style={{marginRight: "20px"}}>{isSignUp ? 'Sign Up' : 'Login'}</button>
      <button type="button" className="cta-button" onClick={() => redirectLogin()}>
        {isSignUp ? 'Login' : "Sign up"}
      </button>
      {!isSignUp && showSuccess && <div>Login successful!</div>}
      {isSignUp && showSuccess && <div>Signup successful!</div>}
    </form>
    </div>
  );
}

export default SignupPage;