import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Assuming you're using js-cookie library
import './LandingPage.css'
import { Link } from 'react-router-dom';

function AccountPage() {
  const [user, setUser] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {

    const fetchUserData = async () => {
      try {
        console.log('fetching user data.')
        console.log('included');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/`, {credentials: 'include'}); // Use the userId in the API endpoint
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle the error gracefully (show an error message to the user)
      }
    };

    fetchUserData();
  }, []); 

  const handleDeleteAccount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/delete-account/${userId}/`, { 
        method: 'DELETE',
        credentials: 'include' // Important if you're sending cookies with the request
      });
      // Handle success (e.g., redirect to home page, clear cookies)
    } catch (error) {
      console.error('Error deleting account:', error);
      // Handle the error (show an error message to the user)
    }
  };
  return (
    <div>
      <header>
        <div class="nav-container">
        <nav >
          <ul>
          <li><Link to="/">Home</Link></li>
            </ul>
            <ul class="right-links">
            <li><Link to="/login">Login</Link></li> {/* Use Link for navigation */}
            <li><Link to="/account">Account</Link></li>
          </ul>
        </nav>
        </div>
      </header>
      <div style={{marginLeft: "10px"}}>
      <h2 style={{textAlign: "left"}}>My Account</h2>
      {user ? (
        <div>
          <table class="account-table">
          {/* <tr>
            <th>Username</th>
            <th>{user.username}</th>
          </tr> */}
          <tr>
            <td>Username</td>
            <td>{user.username}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{user.email}</td>
          </tr>
          <tr>
            <td>Domain</td>
            <td>{user.domain}</td>
          </tr>
          <tr>
            <td>Credits</td>
            <td>{user.credits}</td>
          </tr>
          {/* Add more rows as needed */}
        </table>
          <button className="delete-button" onClick={() => setShowDeleteConfirmation(true)}>Delete Account</button>

          {showDeleteConfirmation && (
            <div>
              <p>Are you sure you want to delete your account? This action is irreversible.</p>
              <button className="delete-button" onClick={handleDeleteAccount}>Yes, Delete</button>
              <button className="delete-button" onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
            </div>
          )}
        </div>
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
    </div>
  );
}

export default AccountPage;